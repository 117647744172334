import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../services/mixpanel";

export const LogoutButton = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  
  const redirectUri = `${window.location.origin}/`;

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: redirectUri,
      },
    });
    Mixpanel.reset();
  };

  const handleManageAccount = async () => {
    setMenuOpen(false);
    navigate("/account");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.user-menu')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

/*   return (
    <div className="user-menu">
      <button onClick={toggleMenu} aria-expanded={menuOpen} aria-haspopup="true" aria-label="Toggle Account Menu">Account <span className="caret">&#x25BE;</span></button>
      {menuOpen && 
        <div className="menu" role="menu">
          <span className="member">{user.name}</span><br/><span className="email">{user.email}</span>
          <button role="menuitem"><span className="manage_account">Manage Account</span></button>
          <hr/>
          <button role="menuitem"><span className="settings">Settings</span></button>
          <hr/>
          <button role="menuitem" onClick={handleLogout}><span className="signout">Sign Out</span></button>
        </div>
      }
    </div>
  ); */

     const firstName = user.name.split(" ")[0];

     return (
    <div className="user-menu">
      <button onClick={toggleMenu} className="legacy-component" aria-expanded={menuOpen} aria-haspopup="true" aria-label="Toggle Account Menu">{firstName} <span className="caret">&#x25BE;</span></button>
      {menuOpen && 
        <div className="menu" role="menu">
          <span className="member">{user.name}</span><br/><span className="email">{user.email}</span>
          <button className="legacy-component" role="menuitem" onClick={handleManageAccount}><span className="manage_account">Account Settings</span></button>
          <hr/>
          <button className="legacy-component"  role="menuitem" onClick={handleLogout}><span className="signout">Sign Out</span></button>
        </div>
      }
    </div>
  ); 
};