
import { ReactSVG } from 'react-svg';

export function getPowerIconById(iconID) {
    const icons = [
      "list-ul", "play-circle", "hourglass", "restaurant", "shopping-bag",
      "lightbulb-alt", "shield", "trophy", "envelope-edit", "calendar-alt",
      "file-edit-alt", "start", "laughing", "balance-scale", "microscope",
      "heart"
    ];
    if (icons.includes(iconID)) {
      let src = "/assets/powers/" + iconID + ".svg";
      return (
        <ReactSVG className="glyph" src={src} alt={iconID} />
      );
    } else {
      let src = "/assets/powers/list-ul.svg";
      return (
        <ReactSVG className="glyph" src={src} alt={iconID} />
      );
    }
}