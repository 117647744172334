import { ObjectCache, MapCache } from "./object-cache";

const WISPY_API_URL = process.env.REACT_APP_WISPY_API_SERVER_URL;

const POWER_CACHE = new MapCache("power-cache", 300);
const INVENTORY_CACHE = new MapCache("power-inventory-cache", 60);
const DEFAULT_POWER_CACHE = new MapCache("power-default-cache", 600);
const CREATED_CACHE = new MapCache("power-created-cache", 60);

function powerListToMap(powers) {
    const mappedPowers = {};
    powers.forEach((power) => {
        mappedPowers[power._id] = power;
    });
    return mappedPowers;
}

function powerMapToList(powers) {
    return Object.values(powers);
}

/**
 * Fetches the list of powers from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const fetchPowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchPowers: invalid accessToken");
    }

    // Return cached powers if available
    const mappedPowers = POWER_CACHE.get();
    if (mappedPowers) {
        return powerMapToList(mappedPowers);
    }

    try {
        // Only get public and approved powers here so we don't show creator's own private powers in the gallery
        const response = await fetch(
            `${WISPY_API_URL}/powers?public=true&approved=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPowers: ${error}`);
        }

        const data = await response.json();

        // Cache the powers
        POWER_CACHE.put(powerListToMap(data));

        return data;
    } catch (error) {
        console.error("Error fetching powers:", error);
        throw error;
    }
};

/**
 * Fetches the list of powers from the API server as non auth user
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const fetchPowersPublic = async () => {
    // Return cached powers if available
    const mappedPowers = POWER_CACHE.get();
    if (mappedPowers) {
        return powerMapToList(mappedPowers);
    }

    try {
        // Only get public and approved powers here so we don't show creator's own private powers in the gallery
        const response = await fetch(`${WISPY_API_URL}/powers/public`);

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPowersPublic: ${error}`);
        }

        const data = await response.json();

        // Cache the powers
        POWER_CACHE.put(powerListToMap(data));
        return data;
    } catch (error) {
        console.error("Error fetching powers:", error);
        throw error;
    }
};

/**
 * Fetches the list of active powers for user from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchActivePowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchActivePowers: invalid accessToken");
    }

    // Return cached powers if available
    const mappedPowers = INVENTORY_CACHE.get();
    if (mappedPowers) {
        return powerMapToList(mappedPowers);
    }

    try {
        const response = await fetch(
            `${WISPY_API_URL}/powers?inventoryOnly=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchActivePowers: ${error}`);
        }

        const data = await response.json();

        // Cache the powers
        INVENTORY_CACHE.put(powerListToMap(data));

        return data;
    } catch (error) {
        console.error("Error fetching activePowers:", error);
        throw error;
    }
};

/**
 * Fetches the list of official powers from the API server that are active/inventory
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchDefaultPowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchDefaultPowers: invalid accessToken");
    }

    // Return cached powers if available
    const mappedPowers = DEFAULT_POWER_CACHE.get();
    if (mappedPowers) {
        return powerMapToList(mappedPowers);
    }

    try {
        const response = await fetch(
            `${WISPY_API_URL}/powers?inventoryOnly=true&creator=officialTMC&fields=_id&fields=name&fields=iconID`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchDefaultPowers: ${error}`);
        }

        const data = await response.json();

        // Cache the powers
        DEFAULT_POWER_CACHE.put(powerListToMap(data));

        return data;
    } catch (error) {
        console.error("Error fetching default powers:", error);
        throw error;
    }
};

/**
 * Fetches the list of powers created by this user from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchCreatedPowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchCreatedPowers: invalid accessToken");
    }

    // Return cached powers if available
    const mappedPowers = CREATED_CACHE.get();
    if (mappedPowers) {
        return powerMapToList(mappedPowers);
    }

    try {
        const response = await fetch(`${WISPY_API_URL}/powers?&mineOnly=true`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchCreatedPowers: ${error}`);
        }

        const data = await response.json();

        // Cache the powers
        CREATED_CACHE.put(powerListToMap(data));

        return data;
    } catch (error) {
        console.error("Error fetching created powers:", error);
        throw error;
    }
};

/**
 * Fetches a single power by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the power to be fetched
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const fetchPowerById = async (accessToken, id) => {
    try {
        const cache = new ObjectCache(`power-${id}`, 60);
        const cachedPower = cache.get();
        if (cachedPower) {
            return cachedPower;
        }

        const response = await fetch(`${WISPY_API_URL}/powers/${id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPowerById: ${error}`);
        }

        const data = await response.json();

        // Cache the power
        cache.put(data);

        return data;
    } catch (error) {
        console.error(`Error fetching power with id ${id}:`, error);
        throw error;
    }
};

/**
 * Fetches a single power by ID from the API server as non auth user
 * @param {string} id - The ID of the power to be fetched
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const fetchPowerByIdPublic = async (id) => {
    try {
        const cache = new ObjectCache(`public-power-${id}`, 60);
        const cachedPower = cache.get();
        if (cachedPower) {
            return cachedPower;
        }

        const response = await fetch(`${WISPY_API_URL}/powers/${id}/public`);

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPowerByIdPublic: ${error}`);
        }

        const data = await response.json();

        // Cache the power
        cache.put(data);

        return data;
    } catch (error) {
        console.error(`Error fetching power with id ${id}:`, error);
        throw error;
    }
};

/**
 * Deletes a power by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the power to be deleted
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const deleteCreatedPower = async (accessToken, id) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/powers/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`deleteCreatedPower: ${error}`);
        }

        POWER_CACHE.pop(id);
        INVENTORY_CACHE.pop(id);
        CREATED_CACHE.pop(id);
        const powerCache = new ObjectCache(`power-${id}`, 60);
        powerCache.delete();

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error deleting power with id ${id}:`, error);
        throw error;
    }
};
