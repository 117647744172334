import React from "react";

export const SignupButton = () => {
  const goToCreateAccount = () => {
    window.location.href = "/#membership";
  };

  return (
    <button className="button__sign-up legacy-component" onClick={goToCreateAccount}>
      <span className="get-wispy-text">Try Wispy&nbsp;</span>for free
    </button>
  );
};
