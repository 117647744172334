import React, { useEffect, useRef } from 'react';
import "./wispy.css";

export const Wispy = (props) => {
    const { colorID, hatID, className } = props;
    const wispyRef = useRef(null);

    useEffect(() => {
        const wispy = wispyRef.current;
        const blinkDur = 0.32; // blink duration in seconds
        const pauseMin = 2;
        const pauseMax = 8;
        let timeoutId;

        function blink(wispy) {
            const eyes = wispy.getElementsByClassName('eye');
            for (let eye of eyes) {
            eye.style.animation = `blink ${blinkDur}s ease-in-out`;
            setTimeout(() => {
                eye.style.animation = 'none';
            }, blinkDur * 1000);
            }
        }

        function randomizeBlink(wispy) {
            const pauseDuration = Math.random() * (pauseMax - pauseMin) + pauseMin;
            timeoutId = setTimeout(() => {
            blink(wispy);
            randomizeBlink(wispy);
            }, pauseDuration * 1000); // convert pause duration to milliseconds
        }

        randomizeBlink(wispy);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className={`wispy-component ${className ? className : ''}`} ref={wispyRef}>
            <img className={`wispy-base wispy-${hatID}`} src="/assets/wispy/wispy-base.png" alt="wispy"/>
            <div className={`${hatID}`} />
            <div className={`wispy-mask color-${colorID} wispy-${hatID}`} ></div>
            
            <div className="eyes">
                <div id="eye-left" className="mask eye eye-left">
                    <img src="/assets/wispy/wispy-eye.png" alt="eye-left"/>
                </div>
                <div id="eye-right" className="mask eye eye-right">
                    <img src="/assets/wispy/wispy-eye.png" alt="eye-right"/>
                </div>
            </div>
        </div>
    );
};
