import React from "react";
import { ReactSVG } from "react-svg";

export const MobileMenuToggleButton = ({ icon, handleClick, src, alt }) => {
  return (
    <span
      className="mobile-nav-bar__toggle material-icons"
      id="mobile-menu-toggle-button"
      onClick={handleClick}
    >
      <ReactSVG className="mobile_nav-bar__toggle-icon" src={icon} alt={alt} />
    </span>
  );
};
