import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Share from '../styles/icons/share.svg';
import { useAccessToken } from "../components/auth/access-token";
import { fetchActivePowers, fetchCreatedPowers, fetchPowers, deleteCreatedPower, fetchPowersPublic } from "../services/powers-api";
import { addToActivePowers, removeFromActivePowers } from "../services/accounts-api";
import { ToastNotification } from "../components/toastNotification/toast-notification";
import { Mixpanel } from '../services/mixpanel';
import { getLocalizedString } from '../utils';

import { ConfirmationDialog } from "../components/confirmation-dialog";
import { getPowerIconById } from "../services/power-utils";
import { Wispy } from "../components/wispy/wispy";
import { getMyHandle } from "../services/accounts-api";
import Clear from '../styles/icons/multiply.svg'
import "../styles/powers.css";

export const PowersPage = () => {
  const { isAuthenticated, isLoading = true } = useAuth0();
  /* State management for Gallery of Powers */
  const [creatorHandle, setCreatorHandle] = useState('');
  const [sharedCreatorHandle, setSharedCreatorHandle] = useState('');
  const [powers, setPowers] = useState([]);
  const [activePowers, setActivePowers] = useState([]);
  const [arePowersLoading, setArePowersLoading] = useState(false);
  const [errorInPowers, setErrorInPowers] = useState(null);
  const [errorInActivePowers, setErrorInActivePowers] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [componentWidth, setComponentWidth] = useState(null);
  const [toastVisible, setToastVisible] = useState(false);
  const componentRef = useRef(null);
  const searchInputRef = useRef(null);
  const location = useLocation();
  const locationParams = new URLSearchParams(location.search);

  // Auth0 User Token for API Access
  const accessToken = useAccessToken();

  // Update component width
  useEffect(() => {
    const updateComponentWidth = () => {
      if (componentRef.current) {
        setComponentWidth(componentRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', updateComponentWidth);
    updateComponentWidth();

    return () => {
      window.removeEventListener('resize', updateComponentWidth);
    };
  }, []);

  /* State management for Created Powers */
  const [createdPowers, setCreatedPowers] = useState([]);
  const [errorInCreatedPowers, setErrorInCreatedPowers] = useState(null);

  // Modal confirmation dialog for removing powers
  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  // Enable tab navigation 
  const openTab = useCallback((tabName) => {
    const eventName = `${tabName}-clicked`;
    Mixpanel.track(eventName);

    const tabContent = document.querySelectorAll(".tabcontent");
    tabContent.forEach(tab => {
      if (tab.id === `${tabName}`) {
        tab.style.display = "block";
      } else {
        tab.style.display = "none";
      }
    });
    const tabLinks = document.querySelectorAll(".tablinks");
    tabLinks.forEach(link => {
      if (link.id === `${tabName}_btn`)
        link.classList.add("active");
      else
        link.classList.remove("active");
    });

    // For Gallery of Powers, set focus within the search box
    if (tabName === "gallery-of-powers" && componentWidth > 1023) {
      const search = document.getElementById("gallery-of-powers-search");
      search?.focus();
    }
  }, [componentWidth])


  //======================================================================
  // Power Card
  //======================================================================

  const PowerCard = React.memo(function PowerCard({ power, uniqueKey, activePowers }) {
    const isActivePower = activePowers?.some((item) => item._id === power._id);
    const isDefaultPower = power._creatorID === "officialTMC";
    const isCreatedPower = power._isOwnedByUser;
    return (
      <div className="power" key={uniqueKey}>
        <div className="power-header">
          <div className="power-icon"><div className="glyph">{getPowerIconById(power?.iconID)}</div></div>
          {(isCreatedPower && isAuthenticated) ? (
            <div className="power-action delete">
              <button className="legacy-component" onClick={() => { handleDeletePower(power?._id); }}>
                <span>
                  <ReactSVG className="button_remove" src="/assets/UI/trash-alt.svg" alt="Delete Power" />
                </span>
              </button>
            </div>
          ) : ""}    
        </div>
        <div className="power-name">
          {getLocalizedString("name", power)?.length > 30
            ? getLocalizedString("name", power)?.substring(0, 30) + "..."
            : getLocalizedString("name", power)}</div>
        <div className="power-creator">
          {isDefaultPower 
            ? <span className="built-in" onClick={() => handleCreatorClicked(power?._creatorHandle)}>
                Built-in Power
              </span>
            : <span className="created" onClick={() => handleCreatorClicked(power?._creatorHandle)}>
                By {power?._creatorHandle.substring(0, 30)}
              </span>
          }
        </div>
        <div className="power-description">
          {getLocalizedString("description", power)?.length > 140
            ? getLocalizedString("description", power)?.substring(0, 140) + "..."
            : getLocalizedString("description", power)}
        </div>
        <div className="power-actions">
          <div className="power-action view">
            <button className="legacy-component" onClick={() => { handleViewPower(power?._id); }}>
              <span>
                <ReactSVG className="button_view" src="/assets/UI/view-power.svg" alt="View Power" />
                See Details
              </span>
            </button>
          </div>
          {(isActivePower && !isDefaultPower && isAuthenticated) ? (
              <div className="power-action remove">
                <button className="legacy-component" onClick={() => { handleRemoveFromActivePowers(power?._id); }}>
                  <span>
                    <ReactSVG className="button_remove" src="/assets/UI/remove-power.svg" alt="Remove Power" />
                    Un-Equip
                  </span>
                </button>
              </div>
            ) : ""}
            {(!isActivePower && !isDefaultPower && isAuthenticated)? (
              <div className="power-action add">
                <button className="legacy-component" onClick={() => { handleAddToActivePowers(power?._id); }}>
                  <span>
                    <ReactSVG className="button_add" src="/assets/UI/add-power.svg" alt="Add Power" />
                    Equip
                  </span>
                </button>
              </div>
            ) : ""}
        </div>
      </div>
    );
  });

  //======================================================================
  // Load Powers
  //======================================================================

  useEffect(() => {
    const loadPowers = async () => {
      try {
        let data;
        console.log({isAuthenticated});
        if (accessToken && isAuthenticated) {
          data = await fetchPowers(accessToken);
        } else {
          data = await fetchPowersPublic();
        }
        setPowers(data);
      } catch (error) {
        setErrorInPowers(error.message);
      }
    };

    const loadActivePowers = async () => {
      try {
        if (accessToken && isAuthenticated) {
          const data = await fetchActivePowers(accessToken);
          setActivePowers(data);
        }
      } catch (error) {
        setErrorInActivePowers(error.message);
      }
    };

    const loadCreatedPowers = async () => {
      try {
        if (accessToken && isAuthenticated) {
          const data = await fetchCreatedPowers(accessToken);
          setCreatedPowers(data);
        }
      } catch (error) {
        setErrorInCreatedPowers(error.message);
      }
    };

    const loadData = async () => {
      if (!isLoading) {
        setArePowersLoading(true);
        try {
          await Promise.all([loadPowers(), loadActivePowers(), loadCreatedPowers()]);
        } catch (error) {
          console.error(error);
        } finally {
          setArePowersLoading(false);
        }
      }
    };
    loadData();
  }, [accessToken, isAuthenticated, isLoading]);

//======================================================================
// Gallery of Powers
//======================================================================

const [powerElements, setPowerElements] = useState([]);

useEffect(() => {
  if (searchInputRef.current && componentWidth > 1023) {
    searchInputRef.current.focus();
  }
}, [componentWidth]);

useMemo(() => {
  const generatePowerElements = powers.filter(power => {
    const name = getLocalizedString("name", power)?.toLowerCase();
    const description = getLocalizedString("description", power)?.toLowerCase();
    const creator = power?._creatorHandle?.toLowerCase();
    const searchQueryLowerCase = searchQuery.toLowerCase();
    if (sharedCreatorHandle) {
      return (creator === sharedCreatorHandle.toLowerCase()) && (name.includes(searchQueryLowerCase) || description.includes(searchQueryLowerCase) || creator.includes(searchQueryLowerCase));
    } else {
      return (name.includes(searchQueryLowerCase) || description.includes(searchQueryLowerCase) || creator.includes(searchQueryLowerCase));
    }
  }).map((power, index) => {
    return (
      <PowerCard
        key={`buildIn-${index}-${power?._id}`}
        uniqueKey={`buildIn-${index}-${power?._id}`}
        power={power}
        activePowers={activePowers}
      />
    );
  });
  setPowerElements(generatePowerElements);
}, [powers, searchQuery, sharedCreatorHandle, activePowers]);

  //======================================================================
  // Equipped Powers
  //======================================================================

  const [activePowerElements, setActivePowerElements] = useState([]);

  useMemo(() => {
    const generateActivePowerElements = activePowers.map((power, index) => (
      <PowerCard
        key={`equippedPowers-${index}-${power?._id}`}
        uniqueKey={`equippedPowers-${index}-${power?._id}`}
        power={power}
        activePowers={activePowers}
      />
    ));
  
    setActivePowerElements(generateActivePowerElements);
  }, [activePowers]);
  
  //======================================================================
  // Member's Creations
  //======================================================================

  const [createdPowerElements, setCreatedPowerElements] = useState([]);

  useMemo(() => {
    const generateCreatedPowerElements = createdPowers.map((power, index) => {
      return (
        <PowerCard
          key={`equippedPowers-${index}-${power?._id}`}
          uniqueKey={`createdPowers-${index}-${power?._id}`}
          power={power}
          activePowers={activePowers}
        />
      );
    });

    setCreatedPowerElements(generateCreatedPowerElements);  
  }, [createdPowers, activePowers]);
  
  //======================================================================
  // Power Actions
  //======================================================================

  const handleAddToActivePowers = async (power_id) => {
    try {
      await addToActivePowers(accessToken, power_id);
      let [newActivePower] = powers.filter((item) => item._id === power_id);
      if (!newActivePower) {
        [newActivePower] = createdPowers.filter((item) => item._id === power_id);
      }
      setActivePowers([...activePowers, newActivePower]);
      /* Send message to update extension UI */
      window.postMessage({
        type: "update-powers"
      }, "*");

      const properties = {
        powerID: power_id,
      };
      Mixpanel.track("add-power-to-gallery-clicked", properties);
    } catch (error) {
      console.error("Error adding to active powers:", error);
    }
  };

  const handleRemoveFromActivePowers = async (power_id) => {
    try {
      await removeFromActivePowers(accessToken, power_id);
      setActivePowers(activePowers.filter((item) => item._id !== power_id));
      /* Send message to update extension UI */
      window.postMessage({
        type: "update-powers"
      }, "*");

      const properties = {
        powerID: power_id,
      };
      Mixpanel.track("remove-power-from-gallery-clicked", properties);
    } catch (error) {
      console.error("Error removing from active powers:", error);
    }
  };

  const handleViewPower = async (power_id) => {
    navigate(`/powers/${power_id}`, { state: { isInApp: true} });
  }

  const handleDeletePower = async (power_id, isPublic) => {
    setShowConfirmation(true);
    const confirmed = await new Promise((resolve) => {
      const onConfirm = () => resolve(true);
      const onCancel = () => resolve(false);
      let message = "Are you sure you want to delete this power? It will be gone forever!";
      if (isPublic) {
        message = message.concat(" Other users will also loose access to this public power.");
      }
      setShowConfirmation(
        <ConfirmationDialog
          message={message}
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmLabel="Delete"
        />
      );
    });
    setShowConfirmation(false);
    if (confirmed) {
      try {
        await removeFromActivePowers(accessToken, power_id);
        await deleteCreatedPower(accessToken, power_id);
        setActivePowers(activePowers.filter((item) => item._id !== power_id));
        setCreatedPowers(createdPowers.filter((item) => item._id !== power_id));
        setPowers(powers.filter((item) => item._id !== power_id));
        /* Send message to update extension UI */
        window.postMessage({
          type: "update-powers"
        }, "*");

        const properties = {
          powerID: power_id,
        };
        Mixpanel.track("delete-power-clicked", properties);
      } catch (error) {
        console.error("Error deleting created power:", error);
      }
    }
  }

  //======================================================================
  // Sharing powers
  //======================================================================
  const showToast = () => {
    setToastVisible(true);
  };

  const closeToast = () => {
    setToastVisible(false);
  };

  useEffect(() => {
    const creatorHandle = locationParams.get('creator_handle');
    if (creatorHandle) {
      openTab("gallery-of-powers");
      setSharedCreatorHandle(creatorHandle);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search,])

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      openTab("gallery-of-powers");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    const fetchHandle = async () => {
      if (accessToken && isAuthenticated && !isLoading) {
        try {
          const { handle } = await getMyHandle(accessToken);
          setCreatorHandle(handle);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchHandle();
  }, [accessToken, isAuthenticated, isLoading])
  
  
  const handleShare = () => {
    let url = new URL(window.location.href);
    navigator.clipboard.writeText(`${url.origin}/powers?creator_handle=${creatorHandle}`);
    showToast();
  }

  const handleClear = () => {
    setSharedCreatorHandle('')
    navigate(`/powers`);
  }

  const handleCreatorClicked = (creatorHandle) => {
    navigate(`/powers?creator_handle=${creatorHandle}`);
  }

  //======================================================================
  // Render /powers page
  //======================================================================

  return (
    <PageLayout componentRef={componentRef}>
      {(toastVisible) && (
        <ToastNotification
          message="Share Link Copied"
          duration={1000}
          onClose={closeToast}
        />
      )}
      <div className={`confirmation-dialog ${showConfirmation ? 'active' : ''}`}>
        {showConfirmation}
      </div>

      <div key={id}>
        {arePowersLoading ? (
          <></>
        ) : (
          <Outlet context={[powers, activePowers, createdPowers, handleAddToActivePowers, handleRemoveFromActivePowers]} />
        )}
      </div>

      <div className="content-layout">

      <div className="gallery-layout">
        
          <div className="power_tabs tab_container">
            <div className="wispy">
              <Wispy/>
            </div>
            <div className="power_tabs">
              {(isAuthenticated && !isLoading) && <button onClick={() => openTab("equipped-powers")} className="tab tablinks active legacy-component" id="equipped-powers_btn">Equipped Powers</button>}
              <button onClick={() => openTab("gallery-of-powers")} className="tab tablinks legacy-component" id="gallery-of-powers_btn">Gallery of Powers</button>
              {(isAuthenticated && !isLoading) && <button onClick={() => openTab("created-powers")} className="tab tablinks legacy-component" id="created-powers_btn">Your Creations</button>}
            </div>  
          </div>

          <div id="equipped-powers" className={`equipped-powers tabcontent`}>
            <div className="equipped-powers-title legacy-component"><h2 className="legacy-component">Wispy's Equipped Powers</h2></div>
            <div className="power-count">{activePowerElements.length} Equipped Powers</div>
            <div className="powers">
              {errorInActivePowers}
              {arePowersLoading ? (
                <div className="loading">Loading your discoveries...</div>
              ) : (
                <ul>{activePowerElements.length ? activePowerElements : `Discover new Powers in the Gallery of Powers.`}</ul>
                )}
            </div>
          </div>

          <div id="created-powers" className="created-powers tabcontent">
            <div className="gallery-title">
              <h2 className="legacy-component">Your Creations</h2>
              <button className="share-btn legacy-component" onClick={handleShare}>
                <span>
                  <ReactSVG className="share-icon" src={Share} alt="Share Powers" />
                </span>
                <span>
                  Share Your Public Creations
                </span>
              </button>
            </div>
            <div className="power-info">
              <div className="power-count">{createdPowerElements.length} Powers Created</div>              
            </div>
              <div className="powers">
                {errorInCreatedPowers}
                {arePowersLoading ? (
                  <div className="loading">Loading your creations...</div>
                ) : (
                  <ul>{createdPowerElements.length ?
                    createdPowerElements :
                    <p className="powers-empty-state-text legacy-component">Learn how to craft your own Powers in the Guide to Crafting Powers.</p>
                  }</ul>
                )}
              </div>
          </div>

          <div id="gallery-of-powers" className="gallery-of-powers tabcontent">
            <div className="gallery-title">
              <h2 className="legacy-component">Gallery of Powers</h2>
            </div>
            { sharedCreatorHandle && 
              <div className="filter-creator">
                <span>Power(s) created by</span>
                <div className="filter-chip">{sharedCreatorHandle}
                  <button className="filter-chip-btn legacy-component" onClick={handleClear}>
                    <ReactSVG className="clear-icon" src={Clear} alt="Clear" />
                  </button>
                </div>
              </div>
            }
            <div className="power-search">
              <input id="gallery-of-powers-search" className="legacy-component" type="text" ref={searchInputRef} placeholder="Search by name, description or creator" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
            </div>
            <div className="power-count">{powerElements.length} Available Powers to add to Wispy</div>
            <div className="powers">
              {errorInPowers}
              {arePowersLoading ? (
                <div className="loading">Loading Gallery of Powers...</div>
              ) :
                <ul>{ powerElements.length ? 
                  powerElements : 
                  <p className="powers-empty-state-text legacy-component">
                    Sadly, there are no Powers available that match your search criteria.
                  </p> }
                </ul>
              }
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};