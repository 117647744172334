import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_ID, { persistence: 'localStorage' });
mixpanel.register({
  'source': "Wispy Web",
});

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  track: (name, props = {}) => {
    Sentry.addBreadcrumb({
      category: 'mixpanel',
      message: 'track',
      data: { 
        user: mixpanel.get_distinct_id(),
        event: name, 
        props: props 
      },
    });
    const response = mixpanel.track(name, props, (callbackResponse) => {
      if (callbackResponse === 0) {
        console.error(`Mixpanel track failed for event: ${name}`);
      }
    });
    if (response === undefined) {
      console.error(`Mixpanel track failed for event: ${name}, Do Not Track may be enabled`);
    }
  },
  reset: () => {
    mixpanel.reset();
  },

};

export let Mixpanel = actions;