import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import Check from '../../styles/icons/check.svg';


import './toast-notification.css';

export const ToastNotification = ({ message, duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  return (
    <div className={`toast-notification ${visible ? 'visible' : ''}`}>
        <ReactSVG className="toast-icon" src={Check} alt="Checkmark"/>
        <div className="message">{message}</div>
    </div>
  );
};
