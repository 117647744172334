import InstallFxButton from "../buttons/install-fx-ext";
import InstallChromeButton from "../buttons/install-chrome-ext";

export const SayHiToWispy = () => {
    return (
        <div className="title started">
            <h1 className="legacy-component">
                <br /> Welcome to Wispy! Let's get you set up.
            </h1>
        </div>
    );
};

export const DownloadButtons = ({ onClick }) => {
    return (
        <div className="blurb  started">
            <div className="download-options">
                <div className="download-button">
                    <InstallChromeButton onClick={onClick} />
                </div>
                <div className="download-button">
                    <InstallFxButton onClick={onClick} />
                </div>
            </div>
        </div>
    );
};