/**
 * Displays our tos with a button to accept, for use with auth0 redirect action
 *
 * Uses google docs embed to display the policies
 */

import React, { useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import "../styles/consent.css";

export const ConsentPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    if (!state) {
        console.error("No state param");
    }
    // The only way auth0 can pass us extra data is through a jwt token
    // We're using it to pass the continue uri so we don't have to hardcode it
    let continueURI;
    try {
        const token = urlParams.get("session_token");
        const decodedToken = jwtDecode(token);
        continueURI = decodedToken.continue_uri;
        if (!continueURI) {
            throw new Error("No continue uri");
        }
    } catch (err) {
        console.error(err);
    }

    const consentErrorRef = useRef(null);
    const continueButtonRef = useRef(null);

    const handleContinue = () => {
        // disable the continue button
        //continueButtonRef.current.disabled = true;
        //continueButtonRef.current.classList.add("disabled");

        if (!state || !continueURI) {
            consentErrorRef.current.style.display = "flex";
            return;
        }

        // Take user back to auth0 to continue
        window.location.href = `${continueURI}?state=${state}`;
    };

    useEffect(() => {
        if (!continueButtonRef.current) {
            return;
        }
        // disable the continue button on load
        //continueButtonRef.current.disabled = true;
        //continueButtonRef.current.classList.add("disabled");

        // enable the continue button after 5 seconds
        //let timeout = window.setTimeout(() => {
            continueButtonRef.current.disabled = false;
            continueButtonRef.current.classList.remove("disabled");
        //}, 5000);

        return () => {
            //clearTimeout(timeout);
        };
    });

    return (
        <div className="content-layout">
            <div className="consent-layout">
                <div className="header">
                    <div className="consent-title">
                        <h6 className="legacy-component">
                            To continue, please read and accept our Terms of
                            Service
                        </h6>
                    </div>
                </div>

                <div id="consent_error_container" ref={consentErrorRef}>
                    <p className="alert-text legacy-component">
                        <svg
                            className="alert-glyph"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.2898 3.86001L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5468 2.53748 20.7239C2.83847 20.901 3.18058 20.9962 3.52978 21H20.4698C20.819 20.9962 21.1611 20.901 21.4621 20.7239C21.7631 20.5468 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.86001C13.5315 3.56611 13.2805 3.32313 12.981 3.15449C12.6814 2.98585 12.3435 2.89726 11.9998 2.89726C11.656 2.89726 11.3181 2.98585 11.0186 3.15449C10.7191 3.32313 10.468 3.56611 10.2898 3.86001V3.86001Z"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 9V13"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 17H12.01"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        An error occurred. Please try again later or contact
                        support@technicalmagic.ai for assistance.
                    </p>
                </div>
                <div className="consent_container">
                    <label className="consent-text legacy-component">
                        By continuing, you agree that you have read and accept
                        our Terms of Service.
                    </label>
                    <button
                        id="continue_button"
                        className="button legacy-component"
                        ref={continueButtonRef}
                        onClick={handleContinue}
                    >
                        I Accept
                    </button>
                </div>
                <div className="consent-iframe">
                    <iframe
                        style={{
                            width: "90vw",
                            maxWidth: "800px",
                            height: "60vh",
                            minHeight: "300px",
                            backgroundColor: "white",
                            padding: "8px",
                            borderRadius: "8px",
                        }}
                        title="Terms of Service"
                        src="https://docs.google.com/document/d/e/2PACX-1vQmoOHfU7E5Q5PkMmXsm0L__BsUTtYNHqbis8cljvbrUzZY98DaZBU6eap6fboj-g/pub?embedded=true"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};
