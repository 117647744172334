import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

import "../styles/landing-page.css";

export const LandingPage = () => {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 500);
        }
    }, []);

    const handleLogIn = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/home",
            },
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    const handleCTA = async () => {
    /*    let price_id = process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID;

        await loginWithRedirect({
            appState: {
                returnTo: "/home",
            },
            authorizationParams: {
                prompt: 'login',
                price_id: price_id
            },
        }); */
        window.location.href = "https://wispy-2.technicalmagic.ai";
    };

    // If isLoading return a progress spinner
    if (isLoading) {
        return (
            <PageLayout>
                <div className="content-layout">
                    <div className="progress-spinner"></div>
                </div>
            </PageLayout>
        );
    }

    // If user is authenticated, redirect to home page
    if (isAuthenticated) {
        window.location.href = "/home";
        return;
    }

    return (
        <PageLayout>
            <div id="landing-page" className="content-layout">
                <div className="beta-ribbon">BETA</div>
                <div className="product-overview">
                    {/* --------------------------------------------------------
                            HERO SECTION 
                        -------------------------------------------------------- */}

                    <h1 className="product-headline legacy-component">
                        <img
                            className="wispy_wordmark_lp"
                            alt="Wispy"
                            src="/assets/icon-white.svg"
                        />
                        <br />
                        Wispy-1
                        <div className="product-head">
                            Our first personal AI agent
                        </div>
                        <br />
                        <div className="cta-button">
                            <button className="product-cta legacy-component" onClick={handleCTA}>
                                <img class="cta-button-logo" src="/assets/icon-color.svg" alt="" />
                                <strong>Get Started with Wispy</strong>
                                <div className="cta-subhead free_trial">7 Day Free Trial</div>
                                <div className="cta-subhead">$4.99/month Membership</div>
                            </button>
                        </div>
                    </h1>

                    <div className="hero">
                        <img alt="Wispy" src="/assets/wispy-1.png" />
                    </div>
                    <div className="cta">
                        <div className="already-sign-in">
                            <p className="legacy-component">
                                Already a member?{" "}
                                <span
                                    className="fake-link"
                                    onClick={() => handleLogIn()}
                                >
                                    Sign In
                                </span>
                            </p>
                        </div>
                        <div className="product-subhead">
                            Shape your own experience as you discover and learn to craft images, compose text, analyze data and more with the latest AI models.
                        </div>
                    </div>


                </div>
            </div>
        </PageLayout>
    );
};
