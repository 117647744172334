import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Close from '../styles/icons/close.svg'
import { getLocalizedString } from '../utils';

import { getPowerIconById } from '../services/power-utils';
import "../styles/components/power-detail.css";

export const PowerDetail = () => {
    const { state } = useLocation();
    const { isAuthenticated, isLoading } = useAuth0();
    const [isActivePower, setIsActivePower] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const [powers, activePowers, createdPowers, handleAddToActivePowers, handleRemoveFromActivePowers] = useOutletContext();

    // Find power_id in powers array, if not in array, it may be a created power that is not yet published
    const power = powers.find(item => item._id === id) || createdPowers.find(item => item._id === id);

    // Check if power exists before accessing its properties
    let isDefaultPower = false;
    let isCreatedPower = false;
    if (power) {
        isDefaultPower = power._creatorID === "officialTMC";
        isCreatedPower = power._isOwnedByUser && isAuthenticated;
    }

    useEffect(() => {
        if(power) {
            setIsActivePower(activePowers?.some((item) => item._id === power._id));
        }
    }, [activePowers, power]);


    const handleClose = () => {
        if (state?.isInApp) {
            navigate(-1);
        } else {
            navigate("/powers");
        }
    };

    if (!power) {
        // TODO: update this to a valid error screen
        // return <div>No detail available for this power.</div>
        return (
            <div id="power-detail-overlay">
                <div id="power-detail-view">
                    <div id="power-detail-header">
                        <div id="modal_close">
                            <ReactSVG className="close" onClick={handleClose} src={Close} alt="Close" />
                        </div>
                    </div>
                    <div className="error">
                        <h4 className="legacy-component">Power Not Found</h4>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="power-detail-overlay">
            <div id="power-detail-view">
                <div id="power-detail-header">
                    <div className="power-icon">
                        <div className="detail-glyph">
                            {getPowerIconById(power?.icon)}
                        </div>
                    </div>
                    <div className="name">{getLocalizedString("name", power)}</div>
                    <div className="creator-equipment-container">
                        <div>
                            <div className="detail-item version">
                                <div className="version">v{power?.version}</div>
                                {isDefaultPower ? <div className="power-default"><ReactSVG className="power-default-icon" src="/assets/UI/default-power.svg" alt="Built-in Power" />Built-in Power</div> : ""}
                                {isCreatedPower ? <div className="power-created">Your Creation</div> : ""}
                            </div>
                        </div>
                        {(isAuthenticated && !isLoading) && 
                        <div id="power-actions" className={isDefaultPower || isCreatedPower ? "hidden" : ""}>
                            {(isActivePower) ? "" : (
                                <div className="power-action add">
                                    <button className="button_add legacy-component" onClick={() => { handleAddToActivePowers(power?._id); }}>
                                        <span>
                                            <ReactSVG className="button_add" src="/assets/UI/add-power.svg" alt="Equip Power" />
                                            Equip
                                        </span>
                                    </button>
                                </div>
                            )}
                            {(!isActivePower) ? "" : (
                                <div className="power-action remove">
                                    <button className="button_remove legacy-component" onClick={() => { handleRemoveFromActivePowers(power?._id); }}>
                                        <span>
                                            <ReactSVG className="button_remove" src="/assets/UI/remove-power.svg" alt="Un-Equip Power" />
                                            Un-Equip
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>}
                    </div>
                    <div id="modal_close">
                        <ReactSVG className="close" onClick={handleClose} src={Close} alt="Close" />
                    </div>
                </div>

                <div id="power-detail-container">
                    <div className="detail">
                        <div className="detail-item description">
                            <span className="title">Description</span>
                            <div className="description">{getLocalizedString("description", power) || "None provided."}</div>
                        </div>
                    </div>

                    <div className="preview">
                        <div id="power-preview-prompt" className="active">
                            <span className="title">Prompt</span>
                            <div className="textarea-container">
                                <textarea readOnly={true} className="prompt legacy-component" value={power?.promptTemplate}></textarea>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};
