import React from "react";
import { PageLayout } from "../components/page-layout";
import InstallFxButton from "../components/buttons/install-fx-ext";
import InstallChromeButton from "../components/buttons/install-chrome-ext";

import "../styles/help.css";

export const HelpPage = () => (
    <PageLayout>
        <div className="content-layout">
            <div className="help-layout">
                <div className="title"><h2 className="legacy-component">Discover Wispy</h2></div>

                <div className="UI"><img src="/assets/UI.png" alt=""/></div>

                <div className="blurb">

                    <div className="doc-options">
                        
                        <div className="doc-button"><InstallChromeButton /></div>
                        <div className="doc-button"><InstallFxButton /></div>

                    </div>


                </div>

            </div>

        </div>
    
    </PageLayout>
);