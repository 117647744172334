import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
    const navigate = useNavigate();
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = `${window.location.origin}/auth0-callback`;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const scope = "openid profile email";

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo ?? "/home");
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: audience,
                scope: scope,
            }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
            legacySameSiteCookie={false}
        >
            {children}
        </Auth0Provider>
    );
};
