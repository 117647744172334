import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
      <img className="wispy_wordmark" alt="Wispy" src="/assets/wispy_wordmark.svg" />
      </NavLink>
    </div>
  );
};
