import { useEffect } from "react";

export const useExtensionDetection = (isInstalled, setInstalled) => {
    useEffect(() => {
        const detectInstalled = () => {
            if (isInstalled) {
                clearInterval(checkInstalledInterval);
                return;
            }
            // Look for a URL parameter indicating that the extension is installed
            // This is a backup option to the message from the extension.
            let url = new URL(window.location.href);
            if (url.searchParams.get("wispy") === "installed") {
                setInstalled(true);
            }
            // If we don't detect the extension, send a message to the extension
            // to see if it's installed.
            if (!isInstalled) {
                window.postMessage({ type: "wispy-check-installed" }, "*");
            }
        };

        let checkInstalledInterval = setInterval(detectInstalled, 2000);
        detectInstalled();

        window.addEventListener("message", (event) => {
            if (
                event.data.type &&
                event.data.type === "wispy-is-installed"
            ) {
                setInstalled(true);
            }
        });
    });
};
