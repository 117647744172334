import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../buttons/button/button";

const CLAIMS_NAMESPACE = "https://wispy.cyberwell.ai";
const MFA_ENABLED_CLAIM = `${CLAIMS_NAMESPACE}/mfa_enabled`;
const MFA_CHALLENGED_CLAIM = `${CLAIMS_NAMESPACE}/mfa_challenged`;

/**
 * This hook is used to determine if the user has been challenged with MFA.
 * It will redirect the user to the MFA challenge page if a challenge is required.
 * @param {Object} options - Options for the hook.
 * @param {boolean} options.showChallengeButton - If true, a button will be rendered that the user can click to view the MFA challenge.
 * @param {string} options.challengeButtonLabel - The label to display on the MFA challenge button.
 */
export const UseMfaChallenge = ({
    showChallengeButton = false,
    challengeButtonLabel = "Click to continue",
} = {}) => {
    const [idTokenClaims, setIdTokenClaims] = useState(null);
    const [mfaOk, setMfaOk] = useState(false);
    const { getIdTokenClaims, user, loginWithRedirect } = useAuth0();

    let ChallengeButton = null;
    if (showChallengeButton) {
        ChallengeButton = (
            <Button
                text={challengeButtonLabel}
                onClick={() => {
                    loginWithRedirect({
                        authorizationParams: {
                            mfa_requested: true,
                        },
                        appState: {
                            returnTo: window.location.pathname,
                        },
                    });
                }}
            />
        );
    }

    useEffect(() => {
        const fetchClaims = async () => {
            const claims = await getIdTokenClaims();
            setIdTokenClaims(claims);
        };
        if (user) {
            fetchClaims();
        }
    }, [user, getIdTokenClaims]);

    useEffect(() => {
        if (idTokenClaims) {
            const mfaEnabled = idTokenClaims[MFA_ENABLED_CLAIM];
            const mfaChallenged = idTokenClaims[MFA_CHALLENGED_CLAIM];

            if (!mfaEnabled) {
                setMfaOk(true);
            } else if (mfaEnabled && !mfaChallenged) {
                setMfaOk(false);
                if (!showChallengeButton) {
                    loginWithRedirect({
                        authorizationParams: {
                            mfa_requested: true,
                        },
                        appState: {
                            returnTo: window.location.pathname,
                        },
                    });
                }
            } else if (mfaEnabled && mfaChallenged) {
                setMfaOk(true);
            } else {
                throw new Error("Invalid MFA state");
            }
        }
    }, [idTokenClaims, loginWithRedirect, showChallengeButton]);

    /**
     * A component that will only render the provided component if the user has passed the MFA challenge.
     * @param {React.Component} component - The component to render if the user has passed the MFA challenge.
     * @returns {React.Component} - The provided component if the user has passed the MFA challenge.
     */
    const withMfaChallenge = (component) => {
        if (!mfaOk) {
            if (showChallengeButton) {
                return ChallengeButton;
            }
            return null;
        }
        return component;
    };

    return { withMfaChallenge, mfaOk };
};
