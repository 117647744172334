export const getLocalizedString = (fieldName, object = {}) => {
    const {
        _defaultLocale = ''
    } = object;
    const field = object[fieldName]
    const userLocale = navigator.language || navigator.userLanguage;
    if (field) {
        return field[userLocale] ?? field[_defaultLocale] ?? field['en-US'] ?? Object.values(field);
    } else {
        return undefined;
    }
}