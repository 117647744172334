import React from 'react';

import './check-box.css'

export const CheckBox = ({
    className,
    isChecked,
    onChange, 
    text,
    onFocus,
    disabled = false,
}) => {
    const handleCheckboxChange = (event) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
    };

    return (
        <label className={`check-box legacy-component ${className}`}>
            <input
                type='checkbox'
                className="legacy-component"
                checked={isChecked}
                onChange={handleCheckboxChange}
                onFocus={handleFocus}
                disabled={disabled}
            />
            {text && <span className='check-box-text'>{text}</span>}
        </label>
    );
};