import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Mixpanel } from '../../services/mixpanel';

const LATEST_EXTENSION_URL = `https://addons.mozilla.org/en-US/firefox/addon/wispy/`; //`https://wispy.dist.technicalmagic.ai/latest`;
const LATEST_EXTENSION_URL_NIGHTLY = `https://addons.mozilla.org/en-US/firefox/addon/wispy/`; //`https://wispy-nightly.dist.technicalmagic.ai/latest`;

const InstallFxButton = ({ onClick, imageOnly }) => {
    let useNightly = false;
    // if email ends in @cyberwell.ai, useNightly = true
    const { user } = useAuth0();
    if (user) {
        useNightly = user.email.endsWith("@cyberwell.ai");
    }

    const triggerDownload = (event) => {
        /* Mixpanel.track("add-to-browser-clicked", { browser: "firefox"});
        let url = useNightly
            ? LATEST_EXTENSION_URL_NIGHTLY
            : LATEST_EXTENSION_URL;
        // Set this location to the download URL so the file downloads
        // on this page without any other tabs flashing open.
        window.location.href = url;
        if (onClick) {
            try {
                onClick();
            } catch (e) {
                console.error(e);
            }
        } */
    };

    const img = (
        <img
            onClick={triggerDownload}
            src="/assets/firefox.png"
            className="browser-icon"
            alt="Add to Firefox"
        />
    );
    if (imageOnly) {
        return img;
    }

    return (
        <button className="legacy-component" onClick={triggerDownload} disabled>
            {img}
            Add to Firefox
        </button>
    );
};

export default InstallFxButton;
