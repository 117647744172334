import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Mixpanel } from '../../services/mixpanel';

const CHROME_STORE_URL = `https://chrome.google.com/webstore/detail/wispy/nbljfchpacfegmmmajcneihieeglpofc`;
const CHROME_STORE_URL_NIGHTLY = CHROME_STORE_URL; //`https://chrome.google.com/webstore/detail/wispy/aellljeibjjalgfoiknknfjjaiceipeb`;

const InstallChromeButton = ({ onClick, imageOnly }) => {
    let useNightly = false;
    // if email ends in @cyberwell.ai, useNightly = true
    const { user } = useAuth0();
    if (user) {
        useNightly = user.email.endsWith("@cyberwell.ai");
    }

    const triggerDownload = (event) => {
        /* Mixpanel.track("add-to-browser-clicked", { browser: "chrome"});
        let url = useNightly ? CHROME_STORE_URL_NIGHTLY : CHROME_STORE_URL;
        // Open in new window because the user needs to click Add to Chrome from the store.
        window.open(url, "_blank", "width=1200,height=600");
        if (onClick) {
            try {
                onClick();
            } catch (e) {
                console.error(e);
            }
        } */
    };

    const img = (
        <img
            onClick={triggerDownload}
            src="/assets/chrome.png"
            className="browser-icon"
            alt="Add to Chrome"
        />
    );
    if (imageOnly) {
        return img;
    }

    return (
        <button className="legacy-component" onClick={triggerDownload} disabled>
            {img}
            Add to Chrome
        </button>
    );
};

export default InstallChromeButton;
