import React from "react";

export const PageFooter = () => {
    // TODO update these links once docs are ready
    return (
        <div className="footer">
            <a href="https://technicalmagic.ai/"><img className="ttmc-footer" alt="Wispy" src="/assets/tmc-logo_outline.svg" /></a>
                        
            <span className="footer-copy">
                <p>
                    <a
                        className="link"
                        href="https://technicalmagic.ai/terms-of-service"
                    >
                        Terms of Service
                    </a>
                    <a className="link" href="https://technicalmagic.ai/privacy">
                        Privacy Policy
                    </a>
                </p>

                <p className="copyright legacy-component">
                    &copy; 2023 The Technical Magic Company. All Rights Reserved.
                </p>
                <p className="reserved legacy-component">
                    The Technical Magic Company&trade; and Wispy&trade; are
                    trademarks of The Technical Magic Company
                </p>
            </span>
        </div>
    );
};
