import { MapCache } from "./object-cache";

export const ACCOUNTS_API_URL = process.env.REACT_APP_ACCOUNTS_API_SERVER_URL;
const POWER_INVENTORY_CACHE = new MapCache("power-inventory-cache", 60);

export const addToActivePowers = async (accessToken, power_id) => {
    if (!accessToken) {
        throw new Error("addToActivePowers: invalid accessToken");
    }
    
    if (!power_id) {
        throw new Error("addToActivePowers: invalid power_id");
    }

    try {
        console.log("PUT /powerInventory for "+power_id);
        const response = await fetch(`${ACCOUNTS_API_URL}/powerInventory`, {
            method: "PUT",
            body: JSON.stringify({
                id: power_id,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("addToActivePowers: resource unavailable, please try again later");
        }

        POWER_INVENTORY_CACHE.delete();

        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error adding to activePowers:", error);
        throw error;
    }
};

export const removeFromActivePowers = async (accessToken, power_id) => {
    if (!accessToken) {
        throw new Error("removeFromActivePowers: invalid accessToken");
    }
    
    if (!power_id) {
        throw new Error("removeFromActivePowers: invalid power_id");
    }

    try {
        console.log("DELETE /powerInventory for "+power_id);
        const response = await fetch(`${ACCOUNTS_API_URL}/powerInventory`, {
            method: "DELETE",
            body: JSON.stringify({
                id: power_id,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("removeFromActivePowers: resource unavailable, please try again later");
        }

        POWER_INVENTORY_CACHE.delete();

        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error removing from activePowers:", error);
        throw error;
    }
};


export const getHandle = async (accessToken, user_id) => {
    if (!accessToken) {
        throw new Error("getHandle: invalid accessToken");
    }
    
    if (!user_id) {
        throw new Error("addToInventory: invalid user_id");
    }

    try {
        console.log("PUT /getHandle for "+user_id);
        const response = await fetch(`${ACCOUNTS_API_URL}/getHandle`, {
            method: "PUT",
            body: JSON.stringify({
                userID: user_id,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("getHandle: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error getting handle for userID:", error);
        throw error;
    }
};

export const getMyHandle = async (accessToken) => {
    if (!accessToken) {
        throw new Error("getMyHandle: invalid accessToken");
    }

    try {
        console.log("Get /handle for logged in user");
        const response = await fetch(`${ACCOUNTS_API_URL}/handle`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("getMyHandle: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error getting handle for logged in user:", error);
        throw error;
    }
};

export const setHandle = async (accessToken, creator_handle) => {
    if (!accessToken) {
        throw new Error("setHandle: Invalid accessToken");
    }

    if (!creator_handle) {
        throw new Error("setHandle: Invalid creator handle");
    }

    try {
        console.log("PUT /handle for logged in user");
        const response = await fetch(`${ACCOUNTS_API_URL}/handle`, {
            method: "PUT",
            body: JSON.stringify({
                handle: creator_handle,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        const data = await response.json();
        console.log(data);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("setHandle: Resource unavailable, please try again later");
        } else if (response.status !== 200) {
            throw new Error(data.error);
        }
        return data;
    } catch (error) {
        console.error("Error setting handle for logged in user:", error);
        throw error;
    }
};

export const getStripePortalUrl = async (accessToken) => {
    const fallbackUrl = "https://billing.stripe.com/p/login/eVabLw3okbxo0TedQQ";
    
    try {
        if (!accessToken) {
            throw new Error("getStripePortalUrl: invalid accessToken");
        }
        console.log("GET /stripe-portal-link");
        const response = await fetch(`${ACCOUNTS_API_URL}/stripe-portal-link`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status !== 200) {
            throw new Error("getStripePortalUrl: resource unavailable, please try again later");
        }
        const data = await response.json();
        return data.url ?? fallbackUrl;
    } catch (error) {
        console.error("Error getting stripe portal url:", error);
        return fallbackUrl;
    }
}

export const getAccountPrefs = async (accessToken) => {
    if (!accessToken) {
        throw new Error("getAccountPrefs: invalid accessToken");
    }

    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/preferences`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        if (response.status !== 200) {
            throw new Error("getAccountPrefs: resource unavailable, please try again later");
        }
        const { preferences } = await response.json();
        return preferences;
    } catch (error) {
        console.error("Error getting Account Prefs:", error);
        throw error;
    }
}

export const setAccountPrefs = async (accessToken, preferences) => {
    if (!accessToken) {
        throw new Error("setAccountPrefs: invalid accessToken");
    }

    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/preferences`, {
            method: "PATCH",
            body: JSON.stringify({
                preferences
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        if (response.status !== 200) {
            console.log(response);
            throw new Error("setAccountPrefs: resource unavailable, please try again later");
        }
        const data = await response.json();
        return data.preferences;
    } catch (error) {
        console.error("Error setting Account Prefs:", error);
        throw error;
    }
}
