import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

/**
 * A custom hook to get the access token from Auth0
 * @returns {string} The access token
 */
export const useAccessToken = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const refreshAccessToken = async () => {
            try {
                // Clear first in case of failure to get a new token
                setAccessToken(null);

                // audience and scope are defined in the Auth0ProviderWithNavigate component
                const newToken = await getAccessTokenSilently();
                setAccessToken(newToken);
            } catch (error) {
                console.error(
                    "useAccessToken: Error getting access token:",
                    error
                );
            }
        };

        const isTokenExpired = (token) => {
            try {
                const decodedToken = jwtDecode(token);
                const expDeadline = Date.now() / 1000 + 60 * 15; // 15 minutes from now
                return decodedToken.exp < expDeadline;
            } catch (error) {
                console.error(
                    "useAccessToken: Error decoding access token:",
                    error
                );
                return true;
            }
        };

        const getAccessToken = async () => {
            if (!accessToken) {
                refreshAccessToken();
            } else if (isTokenExpired(accessToken)) {
                refreshAccessToken();
            }
        };

        getAccessToken();

        // Poll every 15 minutes to refresh the token
        const interval = setInterval(() => {
            getAccessToken();
        }, 15 * 60 * 1000); // 15 minutes in milliseconds

        // Cleanup
        return () => clearInterval(interval);
    }, [accessToken, getAccessTokenSilently]);

    return accessToken;
};

export const usePermissions = () => {
    const accessToken = useAccessToken();
    const [permissions, setPermissions] = useState([]);

    // We decode permissions from the access token.
    // The server is responsible for validating the token,
    // so we can trust the permissions enough to use them in the UI.
    useEffect(() => {
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const permissions = decodedToken.permissions || [];
            setPermissions(permissions);
        }
    }, [accessToken]);

    return permissions;
};
