import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: { returnTo: window.location.pathname },
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    return (
        <button
            className="button__login legacy-component"
            onClick={handleLogin}
        >
            Sign In
        </button>
    );
};
