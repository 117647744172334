import { useState } from "react";

export const ConfirmationDialog = ({
    message,
    onConfirm,
    onCancel,
    confirmLabel,
    cancelLabel,
}) => {
    const [confirmed, setConfirmed] = useState(false);

    const confirmText = confirmLabel || "Confirm";
    const cancelText = cancelLabel || "Cancel";

    const handleConfirm = () => {
        onConfirm();
        setConfirmed(true);
    };

    const handleCancel = () => {
        onCancel();
        setConfirmed(false);
    };

    return (
        <div>
            {!confirmed && (
                <div>
                    <p className="legacy-component">{message}</p>
                    <button className="legacy-component" onClick={handleConfirm}>{confirmText}</button>
                    <button className="legacy-component" onClick={handleCancel}>{cancelText}</button>
                </div>
            )}
            {confirmed && <p>Confirmed</p>}
        </div>
    );
};
