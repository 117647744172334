/**
 * A browser storage wrapper for caching objects such as powers and personas
 */

/**
 * Basic object cache for storing objects in local storage
 */
export class ObjectCache {
    key;
    ttlSeconds;

    constructor(key, ttlSeconds = 60) {
        this.key = key;
        this.ttlSeconds = ttlSeconds;

        if (typeof key !== "string") {
            throw new Error("ObjectCache: key must be a string");
        }

        if (typeof ttlSeconds !== "number") {
            throw new Error("ObjectCache: ttlSeconds must be a number");
        }
    }

    put(value) {
        if (value === null || value === undefined) {
            this.delete();
            return;
        }

        const item = {
            value,
            expires: Date.now() + this.ttlSeconds * 1000,
        };

        try {
            localStorage.setItem(this.key, JSON.stringify(item));
            console.log(this.key, " cache.put: added");
        } catch (error) {
            console.error("Error adding to cache:", error);
            // We just won't use cache if it's full, try to clear it
            this.delete();
        }
    }

    get() {
        let item;
        try {
            item = localStorage.getItem(this.key);
        } catch (error) {
            console.error("Error getting from cache:", error);
            return null;
        }
        if (!item) {
            console.log(this.key, " cache.get: no item found");
            return null;
        }
        const parsed = JSON.parse(item);
        if (parsed.expires < Date.now()) {
            console.log(this.key, " cache.get: item expired");
            this.delete();
            return null;
        }
        return parsed.value;
    }

    delete() {
        try {
            localStorage.removeItem(this.key);
            console.log(this.key, " cache.delete: removed");
        } catch (error) {
            console.error("Error deleting from cache:", error);
        }
    }
}

export class MapCache extends ObjectCache {
    put(value) {
        // ensure value is an object and not a list
        if (typeof value !== "object" || Array.isArray(value)) {
            console.log(
                this.key,
                " cache.put: value must be a dict-type object"
            );
            throw new Error("MapCache: value must be a dict-type object");
        }

        // if it's an empty map, remove it from the cache
        if (Object.keys(value).length === 0) {
            this.delete();
            console.log(this.key, " cache.put: removed for empty map");
            return;
        }

        super.put(value);
    }

    insert(key, value) {
        const map = this.get();
        if (!map) {
            console.log(this.key, " cache.insert: no map found");
            return;
        }
        map[key] = value;
        this.put(map);
        console.log(this.key, " cache.insert: added");
    }

    pop(key) {
        const map = this.get();
        if (!map) {
            console.log(this.key, " cache.pop: no map found");
            return null;
        }
        const value = map[key];
        delete map[key];
        this.put(map);
        console.log(this.key, " cache.pop: removed");
        return value;
    }
}
