import { useNavigate, useSearchParams } from "react-router-dom";


export const Auth0Callback = () => {
    const navigate = useNavigate();

    // get error and error_description from URL query params
    const [searchParams] = useSearchParams();
    const error = searchParams.get("error");
    const error_description = searchParams.get("error_description");

    if (error) {
        console.error(
            `Error: ${error}. Error Description: ${error_description}`
        );
        // Show error message to user in page with button to go back to landing page
        return (
            <div id="login_error_container">
                <p className="alert-text legacy-component">
                    <svg
                        className="alert-glyph"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.2898 3.86001L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5468 2.53748 20.7239C2.83847 20.901 3.18058 20.9962 3.52978 21H20.4698C20.819 20.9962 21.1611 20.901 21.4621 20.7239C21.7631 20.5468 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.86001C13.5315 3.56611 13.2805 3.32313 12.981 3.15449C12.6814 2.98585 12.3435 2.89726 11.9998 2.89726C11.656 2.89726 11.3181 2.98585 11.0186 3.15449C10.7191 3.32313 10.468 3.56611 10.2898 3.86001V3.86001Z"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12 9V13"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12 17H12.01"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    {error_description}
                </p>
                <button className="legacy-component" onClick={() => navigate("/")}>Go Home</button>
            </div>
        );
    }
};
