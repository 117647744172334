import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
      <img className="wispy_wordmark" alt="Wispy" src="/assets/wispy_wordmark.svg" />
      </NavLink>
    </div>
  );
};
