import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import InstallChromeButton from "../components/buttons/install-chrome-ext";
import InstallFxButton from "../components/buttons/install-fx-ext";

import "../styles/home.css";

export const HomePage = () => {
    const navigate = useNavigate();
    const { getIdTokenClaims } = useAuth0();

    const [randomAgent, setRandomAgent] = useState("");

    useEffect(() => {
        const agents = ["agent-1.png", "agent-2.png"];
        const randomIndex = Math.floor(Math.random() * agents.length);
        const randomAgentImage = agents[randomIndex];
        setRandomAgent(randomAgentImage);
    }, []);

    // get logins_count from id token claims and redirect to getting-started if logins_count < 3
    // The very first login might be used by the extension login, which is why we check for < 3
    /* getIdTokenClaims().then((claims) => {
        const logins_count =
            claims?.["https://wispy.cyberwell.ai/logins_count"];
        if (typeof logins_count === "number" && logins_count < 3) {
            navigate("/getting-started");
        }
    }); */

    const handleHelpButton = async () => {
        navigate("/help");
    };

    const handlePWAButton = async () => {
        window.open("https://wispy-2.technicalmagic.ai", "_blank");
    }

    return (
        <PageLayout>

            <div className="content-layout">
                <div className="home-layout">

                    <div className="agents">
                        
                        <div className="agent">
                            <div className="agent-icon">
                                <img alt="Wispy" src="/assets/wispy.png" />


                                <div className="agent-status experiment">BETA</div>
                            </div>

                            <div className="agent-content">
                                <div className="agent-name">Wispy-1</div>
                                <div className="agent-category">Personal AI Agent</div>
                                <div className="agent-creator">
                                    <p className="legacy-component">
                                        Shape your own experience as you discover and learn to craft
                                        images, compose text, analyze data and more with the latest AI models.
                                    </p>

                                    <p className="legacy-component agent-details">
                                        <span class="spec-header">Interface</span>
                                        <span class="spec-detail">Mobile and Desktop App</span>
                                        <span class="spec-header">Runtime</span>
                                        <span class="spec-detail">Local and Hosted</span>
                                        <span class="spec-detail">Multi-model</span>
                                        <span class="spec-detail">Multi-modal</span>
                                        <span class="spec-detail">Multi-step</span>
                                        <span class="spec-header">Data Context</span>
                                        <span class="spec-detail">Web Content</span>
                                        <span class="spec-detail">Documents</span>
                                        <span class="spec-detail">Images</span>
                                    </p>
                                </div>

                                <div className="agent-actions">
                                    <div className="agent-platforms">
                                        <button
                                            className="legacy-component"
                                            onClick={() => {
                                                handlePWAButton();
                                            }}
                                        >
                                            Open in New Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="agent agents-endcap">
                            <div className="agent-icon">
                                <img alt="Wispy" src="/assets/wispy-1.png" />

                                <div className="agent-status beta">DISCONTINUED</div>
                            </div>

                            <div className="agent-content">
                                <div className="agent-name">Wispy OG</div>
                                <div className="agent-category">Web Extension</div>
                                <div className="agent-creator">
                                    <p className="legacy-component">
                                        Discover, create and share unique AI-powered experiences
                                        as you browse the web with Chrome &amp; Firefox.
                                    </p>
                                   
                                    <p className="legacy-component agent-details">
                                        <span class="spec-header">Interface</span>
                                        <span class="spec-detail">Desktop Web Extension</span>
                                        <span class="spec-header">Runtime</span>
                                        <span class="spec-detail">Hosted</span>
                                        <span class="spec-detail">Single Model (GPT-3.5-Turbo)</span>
                                        <span class="spec-header">Data Context</span>
                                        <span class="spec-detail">Web Content</span>
                                    </p>
                                </div>
                            </div>

                            <div className="agent-actions">
                                <div className="agent-platforms">
                                    <InstallChromeButton imageOnly={false} />
                                    <InstallFxButton imageOnly={false} />
                                </div>
                            </div>
                        </div>


                        <div className="agent agents-endcap">
                            <div className="agent-icon-future">
                                <img src={`/assets/${randomAgent}`} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};
