import React from 'react';
import { ReactSVG } from 'react-svg';
import './button.css';

export const Button = (props) => {
  const {
    type,
    className = '',
    disabled,
    buttonType,
    buttonForm,
    iconAlt,
    onClick,
    iconSrc,
    iconClassName = '',
    text,
    ref,
  } = props;

  let componentView;
  switch (type) {
    case 'IconText': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref}
          className={`button-component legacy-component ${className} ${disabled ? 'disabled' : ''}`}
        >
          <div className="button-content">
            <ReactSVG className={`button-icon ${iconClassName}`} src={iconSrc} alt={iconAlt} />
            <span className="button-text">{text}</span>
          </div>
        </button>
      );
      break;
    }
    case 'TextIcon': {
        componentView = (
          <button
            disabled={disabled}
            type={buttonType}
            form={buttonForm}
            onClick={onClick}
            ref={ref}
            className={`button-component legacy-component ${className} ${disabled ? 'disabled' : ''}`}
          >
            <div className="button-content">
              <span className="button-text">{text}</span>
              <ReactSVG className={`button-icon ${iconClassName}`} src={iconSrc} alt={iconAlt} />
            </div>
          </button>
        );
        break;
      }
    case 'Icon': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref}
          className={`button-component legacy-component ${className} ${disabled ? 'disabled' : ''}`}
        >
          <div className="button-content">
            <ReactSVG className={`button-icon ${iconClassName}`} src={iconSrc} alt={iconAlt} />
          </div>
        </button>
      );
      break;
    }
    default: {
        componentView = (
          <button
            disabled={disabled}
            type={buttonType}
            form={buttonForm}
            onClick={onClick}
            ref={ref}
            className={`button-component legacy-component ${className} ${disabled ? 'disabled' : ''}`}
          >
            <div className="button-content">
              <span className="button-text">{text}</span>
            </div>
          </button>
        );
        break;
      }
  }

  return componentView;
};

