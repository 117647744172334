import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../components/page-layout";
import { useExtensionDetection } from "../components/extension-detection";
import {
    DownloadButtons,
    SayHiToWispy
} from "../components/elements/getting-started";
import "../styles/getting-started.css";

export const GettingStartedPage = () => {
    const navigate = useNavigate();
    const [isExtensionInstalled, setExtensionInstalled] = useState(false);
    useExtensionDetection(isExtensionInstalled, setExtensionInstalled);

    useEffect(() => {
        if (isExtensionInstalled) {
            navigate("/home");
        }
    }, [isExtensionInstalled, navigate]);

    const installButtonClicked = () => {
        setExtensionInstalled(true);
    };

    return (
        <PageLayout className="discover-wispy-page">
            <div className="content-layout started-content">
                <div className="started-layout install">
                    <SayHiToWispy />
                    <DownloadButtons onClick={installButtonClicked} />
                </div>
            </div>
        </PageLayout>
    );
};
