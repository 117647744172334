import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LoginButton } from "../../buttons/login-button";
import { LogoutButton } from "../../buttons/logout-button";
import { SignupButton } from "../../buttons/signup-button";

export const NavBarButtons = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return null;
    }

    return (
        <div className="nav-bar__buttons">
            {!isAuthenticated && (
                <>
                    <LoginButton />
                    <SignupButton />
                </>
            )}
            {isAuthenticated && (
                <>
                    <LogoutButton />
                </>
            )}
        </div>
    );
};
