import { React, useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import {
    useAccessToken,
    usePermissions,
} from "../components/auth/access-token";
import { UseMfaChallenge } from "../components/auth/mfa-challenge";
import { Button } from "../components/buttons/button/button";
import { CheckBox } from "../components/inputs/checkBox/check-box";
import { getAccountPrefs, setAccountPrefs } from "../services/accounts-api";
import { getStripePortalUrl } from "../services/accounts-api";

import "../styles/account.css";

export const AccountPage = () => {
    const [prefs, setPrefs] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const accessToken = useAccessToken();
    const permissions = usePermissions();
    const { withMfaChallenge, mfaOk } = UseMfaChallenge();

    useEffect(() => {
        if (!accessToken || !mfaOk) {
            return;
        }
        const fetchPrefs = async () => {
            const accountPrefs = await getAccountPrefs(accessToken);
            setPrefs(accountPrefs);
        };
        fetchPrefs();
    }, [accessToken, mfaOk]);

    const handleStripeButton = async () => {
        const portalUrl = await getStripePortalUrl(accessToken);
        window.open(portalUrl, "_blank");
    };

    const stripeButton = (
        <Button
            text="Manage Subscription"
            className="account-button"
            onClick={handleStripeButton}
            disabled={!accessToken}
        />
    );

    const handleSaveButton = async () => {
        try {
            const preferences = await setAccountPrefs(accessToken, prefs);
            setPrefs(preferences);
            setUnsavedChanges(false);
        } catch (error) {
            console.error(error);
            // TODO: Show error message
        }
    };

    let saveInteractionsCheckbox = null;

    if (permissions.includes("write:memory")) {
        saveInteractionsCheckbox = (
            <CheckBox
                text="Save my interactions to Wispy's memory"
                isChecked={prefs.saveInteractions}
                onChange={(checked) => {
                    setPrefs({ ...prefs, saveInteractions: checked });
                    setUnsavedChanges(true);
                }}
            />
        );
    }

    const prefCheckboxes = [];
    if (saveInteractionsCheckbox) {
        prefCheckboxes.push(saveInteractionsCheckbox);
    }

    let savePrefsButton = null;
    if (prefCheckboxes.length > 0) {
        savePrefsButton = (
            <Button
                text="Save Settings"
                className="account-button"
                onClick={handleSaveButton}
                disabled={!unsavedChanges}
            />
        );
    }

    return (
        <PageLayout>
            <div className="content-layout">
                <div className="account-layout">
                    <div className="header">
                        <div className="content__title">
                            <h2 className="legacy-component">Account Settings</h2>
                        </div>
                    </div>
                    <div className="account-prefs">
                        {withMfaChallenge(
                            <div className="preferences">
                                {stripeButton}
                                {prefCheckboxes}
                                {savePrefsButton}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};
