import React from "react";
import { PageLayout } from "../components/page-layout";

export const SignedOutPage = () => (
    <PageLayout>
        <div className="content-layout">
            <h1 id="page-title" className="content__title legacy-component">
                You have signed out.
            </h1>
        </div>

    </PageLayout>
);