import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Auth0Callback } from "./pages/auth0-callback";
import { AuthenticationGuard } from "./components/auth/authentication-guard";
import { useAuth0 } from "@auth0/auth0-react";
import { Mixpanel } from "./services/mixpanel";
import { LandingPage } from "./pages/landing-page";
import { GettingStartedPage } from "./pages/getting-started";
import { HomePage } from "./pages/home";
import { PowersPage } from "./pages/powers";
import { PowerDetail } from "./components/power-detail";
import { HelpPage } from "./pages/help";
import { SignedOutPage } from "./pages/signed-out";
import { NotFoundPage } from "./pages/not-found-page";
import { AccountPage } from "./pages/account";
import { ConsentPage } from "./pages/consent";

export const App = () => {
    const location = useLocation();
    const { isLoading, user } = useAuth0();

    useEffect(() => {
        let eventName = "";
        if (location.pathname === "/") {
            eventName = "landing-page-visited";
        } else {
            eventName = `${location.pathname.substring(1)}-visited`;
        }
        Mixpanel.track(eventName);
    }, [location]);

    useEffect(() => {
        if (user?.sub) {
            Mixpanel.identify(user.sub);
            setTimeout(() => {
                window.postMessage({ type: "wispy-signed-in" }, "*");
            }, 1000);
        }
    }, [user]);

    // Wait for auth to load before using AuthenticationGuard to reduce redirects
    if (isLoading) {
        return null;
    }

    return (
        <Routes>
            {/* Landing Page */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/landing-page" element={<LandingPage />} />

            {/* Auth0 Callback */}
            <Route path="/auth0-callback" element={<Auth0Callback />} />

            {/* Auth0 SignOut Redirect */}
            <Route path="/signed-out" element={<SignedOutPage />} />

            {/* Getting Started */}
            <Route
                path="/getting-started"
                element={<AuthenticationGuard component={GettingStartedPage} />}
            />

            {/* Home */}
            <Route
                path="/home"
                element={<AuthenticationGuard component={HomePage} />}
            />
            <Route
                path="/agents"
                element={<AuthenticationGuard component={HomePage} />}
            />

            {/* Powers */}
            <Route path="/powers" element={<AuthenticationGuard component={PowersPage} />}>
                <Route path=":id" element={<AuthenticationGuard component={PowerDetail} />} />
            </Route>
            <Route path="/gallery" element={<AuthenticationGuard component={PowersPage} />}>
                <Route path=":id" element={<AuthenticationGuard component={PowerDetail} />} />
            </Route>

            {/* Help */}
            <Route
                path="/help"
                element={<AuthenticationGuard component={HelpPage} />}
            />
            <Route
                path="/support"
                element={<AuthenticationGuard component={HelpPage} />}
            />

            {/* Account */}
            <Route
                path="/account"
                element={<AuthenticationGuard component={AccountPage} />}
            />

            {/* Consent Page for Auth0 redirect action */}
            <Route path="/consent" element={<ConsentPage />} />

            {/* Everything else */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};
