import React from "react";
import { useLocation } from "react-router-dom";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";

export const PageLayout = ({ children, componentRef, className }) => {
  const location = useLocation();
  const hideNavBarRoutes = ["/", "/consent", "/auth0-callback", "/landing-page"];

  return (
    <div className={`page-layout ${className ? className : ''}`} ref={componentRef}>
      {!hideNavBarRoutes.includes(location.pathname) && <NavBar />}
      {!hideNavBarRoutes.includes(location.pathname) && <MobileNavBar />}
      <div className="page-layout__content">{children}</div>
      <PageFooter />
    </div>
  );
};
