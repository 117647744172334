import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./components/auth/auth0-provider-with-navigate";
import "./styles/styles.css";

import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";

import { CaptureConsole } from "@sentry/integrations";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: process.env.REACT_APP_ENV === "dev" ? 0 : 0.01,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Replay({
      maskAllText: true,
      blockAllMedia: true,
    }), 
    new CaptureConsole({ 
      levels: ["error", "warn"] 
    }),
  ],
  // Do not capture session in prod except when an error occurs
  replaysSessionSampleRate: process.env.REACT_APP_ENV === "dev" ? 1.0 : 0,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);
